import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 公共路由
export const constantRoutes = [
  {
    path: '/',
    name:'DashBoard',
    component: () => import('@/views/DashBoard'),
  },
  {
    path: '/newsone',
    name:'NewsOne',
    component: () => import('@/views/NewsOne'),
  },
  {
    path: '/newstwo',
    name:'NewsTwo',
    component: () => import('@/views/NewsTwo'),
  },
  {
    path: '/newsthree',
    name:'NewsThree',
    component: () => import('@/views/NewsThree'),
  },
  {
    path: '/productinfo',
    name:'ProductInfo',
    component: () => import('@/views/ProductInfo'),
  },
  {
    path: '/aboutus',
    name:'AboutUs',
    component: () => import('@/views/AboutUs'),
  },
  {
    path: '/solutioncenter',
    name:'SolutionCenter',
    component: () => import('@/views/SolutionCenter'),
  }
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
